<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        電子表單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="申請狀態" >
          <b-form-select v-model="searchStatus" @change="getData">
            <option value="" disabled>請選擇申請狀態</option>
            <option value="1">未申請</option>
            <option value="2">申請中</option>
            <option value="3">已核准</option>
          </b-form-select>
        </b-input-group>
        <b-button variant="primary" @click="getData">查詢</b-button>
        <b-button variant="warning" @click="openModal(1)">新增申請</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="padding: 0px 0 0 30px;-webkit-box-orient: horizontal;">
        <b-button
          variant="success"
          size="sm"
          @click ="approveItem(item)"
          class="mr-2"
          :disabled="item.ApplyStatus == 2　|| item.ApplyStatus == 3"
          >提出</b-button
        >
        <b-button
          variant="primary"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          :disabled="item.ApplyStatus == 2　|| item.ApplyStatus == 3"
          >編輯</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          @click="deleteModal(item)"
          class="mr-2"
          :disabled="item.ApplyStatus == 2　|| item.ApplyStatus == 3"
          >刪除</b-button
        >
      </div>
      <div :title="item.HealthName">
        {{ item.HealthName || "" }}
      </div>

      <div :title="item.Department">
        {{ item.Department || "" }}
      </div>
      <div>
        {{ item.ServDate_S ? $twDate(item.ServDate_S) : "" }} ~ {{ item.ServDate_E ? $twDate(item.ServDate_E) : "" }}
      </div>
      <div :title="item.Remark">
        {{ item.Remark || "" }}
      </div>
      
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <!--${modalType == 1 ? '新增' : '修改'}-->
    <div id="新增視窗">
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '修改'}分發服務申請書`"
        size="lg"
      >
        <div class="modal-grid-2">
          <!--b-input-group prepend="申請類別">
            <b-form-select v-model="modalItem.ApplyCategory">
              <option value="T">訓練</option>
              <option value="S">服務</option>
            </b-form-select>
          </b-input-group-->
          <!--b-input-group prepend="訓練類別">
            <b-form-select v-model="modalItem.TrainCategory">
              <option value="P">PGY</option>
              <option value="S">專科</option>
            </b-form-select>
          </b-input-group-->
          <b-input-group prepend="訓練醫院" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.HealthName"></b-input>
          </b-input-group>
          <b-input-group prepend="科別" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.Department"></b-input>
          </b-input-group>
          <br/>
          <b-input-group prepend="服務時間" class="mt-2" style="grid-column: 1/-1;">
            <date-picker
                v-model="modalItem.ServDate_S"
                :time="modalItem.ServDate_S"
                type="date"
                @update="(t) => (modalItem.ServDate_S = t)"
                placeholder="開始日期"
                :class="modalItem.isForever ? 'avoid-clicks' : ''"
              ></date-picker>
              <span class="mx-2">~</span>
              <date-picker
                v-model="modalItem.ServDate_E"
                :time="modalItem.ServDate_E"
                type="date"
                @update="(t) => (modalItem.ServDate_E = t)"
                placeholder="結束日期"
                :class="modalItem.isForever ? 'avoid-clicks' : ''"
              ></date-picker>
          </b-input-group>
          <!--b-input-group prepend="服務時間" class="mt-2">
            <date-picker-tw
              :time="modalItem.ServDate_S"
              @update="(t) => (modalItem.ServDate_S = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group-->
          <!--div class="mt-2" style="grid-column: 1/-1">
            <quill-editor
              v-model="modalItem.Content"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            >
            </quill-editor>
          </div-->
          <b-input-group prepend="通訊地址" class="mt-2">
            <b-input v-model="modalItem.ApplyAddrs"></b-input>
          </b-input-group>
          <b-input-group prepend="聯絡電話" class="mt-2">
            <b-input v-model="modalItem.ApplyTel"></b-input>
          </b-input-group>
          <b-input-group prepend="email" class="mt-2">
            <b-input v-model="modalItem.Applyemail"></b-input>
          </b-input-group>
          <b-input-group prepend="備註" style="grid-column: 1/-1;" class="mt-2">
            <b-input v-model="modalItem.Remark"></b-input>
          </b-input-group>
          <b-input-group prepend="佐證資料(錄取通知)" style="grid-column: 1/-2;" class="mt-2">
            <div class="input-group">
              <div class="custom-file">
                <input type="file" class="custom-file-input" @change="handleFileUpload($event)" ref="fileInput">
                <label class="custom-file-label">{{ uploadedFileName || modalItem.fileName }}</label>
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" @click="openFileInput">選擇檔案</button>
              </div>
            </div>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "訓練醫院", key: "HealthName", sortDesc: null },
  { name: "科別", key: "Department", sortDesc: null },
  { name: "服務期間", key: "ServDate_S", sortDesc: null },
  { name: "備註", key: "Remark", sortDesc: null },
];
/*{ name: "操作", key: "", sortDesc: null },
{ name: "隸屬單位", key: "HealthCode", sortDesc: null },
  { name: "主旨", key: "Subject", sortDesc: null },
  { name: "上線時間", key: "StartDate", sortDesc: null },
  { name: "下線時間", key: "EndDate", sortDesc: null },
  { name: "建立人", key: "Creater", sortDesc: null },
  { name: "建立時間", key: "CreateTime", sortDesc: null },
  { name: "修改人", key: "Update", sortDesc: null },
  { name: "修改時間", key: "UpdateTime", sortDesc: null },
  { name: "備註", key: "Remark", sortDesc: null },*/
const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      uploadedFileName: '',
      headersContent,
      items: [],
      //ApplyCategory: null, 
      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        ApplyCategory: "",
        ApplyStatus: "",
        ApplyName: "",
        ApplyID: "",
        ApplyAddrs: "",
        ApplyTel: "",
        TrainCategory: "",
        HealthName: "",
        Department: "",
        TrainDate_S: "",
        TrainDate_E: "",
        ServDate_S: "",
        ServDate_E: "",
        Remark: "",
        CreateDate: "",
        Applyemail: "",
      },
      modalType: 1,
      searchStatus: "",
      //search
      searchSubject: "",
      searchRemark: "",
      searchUnit: null,

      //searchOptions or ModalOptions

      content: "<h2></h2>",
      editorOption: {
        // some quill options
      },
      healthCodeOptions: [],
    };
  },
  components: {
    quillEditor,
    Treeselect,
    DatePicker,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const opsHpitalArr = JSON.parse(sessionStorage.getItem("opsHpital"));
      const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      const roleHealthBureauArr = JSON.parse(
        sessionStorage.getItem("roleHealthBureau")
      );
      const roleHealthCenter = JSON.parse(
        sessionStorage.getItem("roleHealthCenter")
      );
      const allArr = [
        ...opsHpitalArr,
        ...stuChoolArr,
        ...roleHealthBureauArr,
        ...roleHealthCenter,
      ];
      return allArr.includes(loginHealthCode);
    },
  },

  methods: {
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(type, item) {
      const codeNow = sessionStorage.getItem("darcok").replace(/\"/g, "");
      this.modalType = type;
      if (type == 1) {
        this.modalItem = {
          //ApplyCategory: "",
          HealthName: "",
          Department: "",
          ServDate_S: "",
          ServDate_E: "",
          Remark: "",
          ApplyAddrs: "",
          ApplyTel: "",
          URL: "",
          //ServDate_S: "",
          fileName: "",
          Applyemail: "",
        };
      } else {
        this.modalItem = JSON.parse(JSON.stringify(item));
        this.modalItem.ServDate_S = new Date(item.ServDate_S);
        this.modalItem.ServDate_E = new Date(item.ServDate_E);
        //this.modalItem.ServDate_S = this.$twDate(item.ServDate_S);
        this.modalItem.fileName = this.modalItem.URL.split('\\').pop().split('/').pop();
        //console.log(modalItem.fileName);
      }
      this.$bvModal.show("editModal");
    },
    async saveModal() {
      //const url = `api/applyform5`;
      const obj = {
        ...this.modalItem,
      };
      obj.ServDate_S.setDate(obj.ServDate_S.getDate() + 1);
      obj.ServDate_E.setDate(obj.ServDate_E.getDate() + 1);
      obj.ApplyCategory = "S";
      //obj.EndDate = this.$usDate(obj.EndDate);
      try {

        const file = this.$refs.fileInput.files[0];
        if (file) {
          const uploadResponse = await this.uploadFile(file);
            obj.URL = uploadResponse;
        }


        this.modalType == 1
          ? await window.axios.post('applyform5/Post', obj)
          : await window.axios.put('applyform5/Put', obj);
        this.$bvToast.toast(
          `${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteModal(item) {
      const url = `applyform5/Delete?Id=${item.SeqNo}`;

      try {
        await window.axios.delete(url);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {

      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      if(this.searchStatus != ""){
        qs = `${qs}&$filter=ApplyStatus eq '${this.searchStatus}'`;
        qs = this.$appendSubstringofFilter("ApplyCategory", qs, "S");
      }else if(this.searchStatus == ""){
        qs = `${qs}&$filter=ApplyCategory eq 'S'`;
        qs = this.$appendSubstringofFilter("ApplyStatus", qs, "1");
      }
      try {
        const { Items, Count } = await window.axios.get(
          `applyform5/Get${qs}`
        );
          // 修改資料處理部分
        this.items = Items.map((s) => ({
            SeqNo: s.SeqNo || "",
            ApplyCategory: s.ApplyCategory || "",
            ApplyStatus: s.ApplyStatus || "",
            //gender: !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女",
            //ApplyName: s.ApplyName || "",
            //ApplyID: s.ApplyID || "",
            ApplyAddrs: s.ApplyAddrs || "",
            ApplyTel: s.ApplyTel || "",
            //TrainCategory: s.TrainCategory || "",
            HealthName: s.HealthName || "",
            Department: s.Department || "",
            //TrainDate_S: s.TrainDate_S || "",
            //TrainDate_E: s.TrainDate_E || "",
            ServDate_S: s.ServDate_S || "",
            ServDate_E: s.ServDate_E || "",
            Remark: s.Remark || "",
            Applyemail: s.Applyemail || "",
            //CreateDate: s.CreateDate || "",
            URL: s.URL,
          }));
          //console.log(Items);
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await window.axios.post('applyform5/importapplyform5', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('文件上傳成功');
            return response.filePath;
        } catch (error) {
            console.error('文件上傳失敗', error);
            throw new Error('文件上傳失敗');
        }
    },
    /*async getAllHealthCodeOption() {
      const url1 = `SysRole/GetRoleInstitutions?no=Admin`;
      const url2 = `SysRole/GetRoleInstitutions?no=Hospital1`;
      const url3 = `SysRole/GetRoleInstitutions?no=Supervisor`;
      const url4 = `SysRole/GetRoleInstitutions?no=user1`;
      const url5 = `SysRole/GetRoleInstitutions?no=user3`;
      const url6 = `SysRole/GetRoleInstitutions?no=user4`;

      const arr1 = await window.axios.get(url1);
      const arr2 = await window.axios.get(url2);
      const arr3 = await window.axios.get(url3);
      const arr4 = await window.axios.get(url4);
      const arr5 = await window.axios.get(url5);
      const arr6 = await window.axios.get(url6);
      let allArr = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5, ...arr6];
      let allArr1 = allArr.map((s) => ({
        id: s.No,
        label: `${s.Name}【${s.No}】`,
        name: s.Name,
      }));

      const uniqueArr1 = this.getUniqueListBy(allArr1, "id");

      this.healthCodeOptions = JSON.parse(JSON.stringify(uniqueArr1));
    },*/
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },

    handleFileUpload(event) {
      const file = event.target.files[0]; // 上傳的文件
      this.uploadedFileName = file.name; // 文件名給 uploadedFileName
      // 
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    async approveItem(item) {
      this.modalItem = { ...item }; // 使用傳遞進來的 item 更新 modalItem
      this.modalItem.ApplyStatus = "2";
      this.ProposeModal();
    },
    async ProposeModal() {
      //const url = `api/applyform5`;
      const obj = {
        ...this.modalItem,
      };
      try {
        const response = await window.axios.put('applyform5/Put', obj);
        console.log("API response:", response); // 檢查 API 響應
        this.$bvToast.toast(
          `已送出`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    //await this.getAllHealthCodeOption();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 220px 250px 250px 220px 180px ;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 33px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}
.custom-file-input:lang(en)~.custom-file-label::after {
  //隱藏Browse按鈕
    content: none;
  }
.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 160px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}
::v-deep .ql-container {
  height: 460px;
}
</style>
